import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PhoneIcon, MailIcon } from "@heroicons/react/outline"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Contact = () => {
  const [emailState, setEmailState] = React.useState("")
  const [emailErrorState, setEmailErrorState] = React.useState("")
  const [companyState, setCompanyState] = React.useState("")
  const [phoneState, setPhoneState] = React.useState("")
  const [messageState, setMessageState] = React.useState("")
  const [messageErrorState, setMessageErrorState] = React.useState("")

  const isEmpty = value => (value ? false : true)

  const isInvalidEmail = value => {
    let regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !regEmail.test(value)
  }

  const validateEmail = email => {
    if (isEmpty(email)) {
      setEmailErrorState("E-mail is verplicht")
      return false
    }

    if (isInvalidEmail(email)) {
      setEmailErrorState("Email is ongeldig")
      return false
    }

    if (!isEmpty(email) && !isInvalidEmail(email)) {
      setEmailErrorState("")
      return true
    }
  }

  const emailHandler = e => {
    setEmailState(e.target.value)
    validateEmail(e.target.value)
  }

  const companyHandler = e => {
    setCompanyState(e.target.value)
  }

  const phoneHandler = e => {
    setPhoneState(e.target.value)
  }

  const validateMessage = message => {
    if (isEmpty(message)) {
      setMessageErrorState("Bericht is verplicht")
      return false
    } else {
      setMessageErrorState("")
      return true
    }
  }

  const messageHandler = e => {
    setMessageState(e.target.value)
    validateMessage(e.target.value)
  }

  let disabled = true

  if (emailErrorState || messageErrorState) {
    disabled = true
  } else {
    disabled = false
  }

  const submitHandler = e => {
    e.preventDefault()

    const emailValid = validateEmail(emailState)

    const messageValid = validateMessage(messageState)

    if (emailValid && messageValid) {
      // Simple POST request with a JSON body using fetch
      disabled = true

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: emailState,
          phone: phoneState,
          company: companyState,
          message: messageState,
        }),
      }

      fetch(`${process.env.GATSBY_BASE_URL}/api/contact-mail`, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error("Something went wrong")
          }

          toast.success("Je bericht werd verzonden!")

          setEmailState("")
          setCompanyState("")
          setPhoneState("")
          setMessageState("")

          disabled = false
        })
        .catch(error => {
          toast.error(
            "Oops! Er is iets fout gegaan. Gelieve opnieuw te proberen."
          )

          console.error(error)

          disabled = false
        })
    }
  }

  const { allPagesJson } = useStaticQuery(
    graphql`
      {
        allPagesJson(filter: { page: { eq: "contact" } }) {
          edges {
            node {
              heading
              description
            }
          }
        }
      }
    `
  )

  const data = allPagesJson.edges[0].node

  return (
    <section className="pt-16 pb-32">
      <ToastContainer />
      <div className="px-4 mx-auto contain">
        <div className="max-w-2xl mx-auto lg:max-w-3xl">
          <div className="mb-12 text-center">
            <h1 className="text-4xl font-bold font-heading">{data?.heading}</h1>
            <p className="text-gray-500">{data?.description}</p>
          </div>
          <div className="flex flex-wrap -mx-3 text-center">
            <div className="w-full px-3 mb-12 md:w-1/2">
              <PhoneIcon className="w-8 h-8 mx-auto mb-6 text-blue-500" />
              <div className="leading-relaxed">
                <span className="text-sm text-gray-400">Telefoon</span>
                <p>
                  <a className="hover:underline" href="tel:+32493134671">
                    +324 93 13 46 71
                  </a>
                </p>
              </div>
            </div>
            <div className="w-full px-3 mb-12 md:w-1/2 lg:w-1/3">
              <MailIcon className="w-8 h-8 mx-auto mb-6 text-blue-500" />
              <div className="leading-relaxed">
                <span className="text-sm text-gray-400">E-mail</span>
                <p>
                  <a
                    className="hover:underline"
                    href="mailto:wouter@prioflux.com"
                    rel="noreferrer"
                  >
                    wouter@prioflux.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div>
            <form name="contact" method="post" onSubmit={submitHandler}>
              <div className="flex flex-wrap mb-8 -mx-3">
                <div className="inline-flex flex-col w-full px-3 space-y-5 lg:w-1/2">
                  <div className="w-full">
                    <label htmlFor="email" className="flex justify-between">
                      <span className="label">E-mail</span>
                      {emailErrorState && (
                        <span className="text-sm font-bold text-red-500">
                          {emailErrorState}
                        </span>
                      )}
                    </label>
                    <input
                      id="email"
                      name="email"
                      className={`block w-full p-5 my-2 text-lg text-gray-900 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500 ${
                        emailErrorState
                          ? "border-2 border-red-500 focus:outline-none focus:ring-red-200 focus:border-red-500"
                          : ""
                      } `}
                      type="email"
                      placeholder="E-mail"
                      value={emailState}
                      onChange={emailHandler}
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="company">
                      <span className="flex justify-between">
                        <span className="label">Bedrijf</span>
                        <span className="text-sm">Optioneel</span>
                      </span>
                    </label>
                    <input
                      id="company"
                      name="company"
                      className="block w-full p-5 my-2 text-lg text-gray-900 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500"
                      type="text"
                      placeholder="Bedrijf"
                      value={companyState}
                      onChange={companyHandler}
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="phone">
                      <span className="flex justify-between">
                        <span className="label">Telefoon</span>
                        <span className="text-sm">Optioneel</span>
                      </span>
                    </label>
                    <input
                      id="phone"
                      name="phone"
                      className="block w-full p-5 my-2 text-lg text-gray-900 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500"
                      type="text"
                      placeholder="Telefoon"
                      value={phoneState}
                      onChange={phoneHandler}
                    />
                  </div>
                </div>
                <div className="w-full px-3 mb-4 lg:w-1/2">
                  <label htmlFor="message" className="flex justify-between">
                    <span className="label">Bericht</span>
                    {messageErrorState && (
                      <span className="text-sm font-bold text-red-500">
                        {messageErrorState}
                      </span>
                    )}
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className={`block w-full h-full p-5 my-2 text-lg text-gray-900 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500 ${
                      messageErrorState
                        ? "border-2 border-red-500 focus:outline-none focus:ring-red-200 focus:border-red-500"
                        : ""
                    }`}
                    type="text"
                    placeholder="Bericht..."
                    value={messageState}
                    onChange={messageHandler}
                  ></textarea>
                </div>
              </div>
              <div className="flex items-center justify-center ">
                <button
                  className={`block py-3 font-bold text-gray-900 rounded-md sm:inline-block text-lg ${
                    disabled
                      ? "cursor-not-allowed bg-gray-400 px-6"
                      : "bg-growing-underline focus-ring"
                  }`}
                  disabled={disabled}
                  type="submit"
                >
                  Verzenden
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
